<template>
  <div class="address_wrapper">
    <div class="main_box">
      <div class="title">
         “ —鍵生成屬於自己的靚號錢包地址，<br>
        靚號長度越長等待時間越久輸入幾位就生成幾位，<br>
        可以只有前綴或者後綴，也可以同時存在”
      </div>
      <div class="create_box">
        <span class="label">0x</span>
        <div class="base_input">
          <input type="text" v-model="head" placeholder="前缀输入" />
        </div>
        <span class="label lbale2">000000000</span>
        <div class="base_input">
          <input type="text" v-model="tail" placeholder="后缀输入" />
        </div>
      </div>
      <div class="base_btn2" @click="createWallet">
        開始生成
      </div>
      <div>*建议前缀+后缀最多输入不超过五位</div>
      <div class="base_title base_title_wallet" v-show="wallets.length">
        地址信息
      </div>
      <div class="wallets_list">
        <div class="wallets_item" v-for="(item, index) in wallets" :key="index">
          <div class="wallets_item_info">
            <div class="left_info">
              <span class="left_label">地址</span>
              <span class="left_text">{{item.address}}</span>
            </div>
            <img src="../assets/img/w6.png" @click="clipboardSuccess($event, item.address)" class="copy_icon" srcset="" />
          </div>
          <div class="wallets_item_info">
            <div class="left_info">
              <span class="left_label">私鑰</span>
              <span class="left_text"
                >{{item.privateKey}}</span
              >
            </div>
            <img src="../assets/img/w6.png" @click="clipboardSuccess($event, item.privateKey)" class="copy_icon" srcset="" />
          </div>
        </div>
      </div>
      <div class="right_bottom"></div>
    </div>
  </div>
</template>

<script>
import clip from "../utils/clipboard";

export default {
  data() {
    return {
      head: '',
      tail: '',
      workers: [],
      workerLen: 8,
      wallets: [],
      msg: null
    }
  },
  methods: {
    createWallet() {
let head = this.head;
    let tail = this.tail;
    this.wallets = []
    this.workers = []
    let that = this
    this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
    for (let i = 0; i < this.workerLen; ++i) {
        //本地跑
         let worker = new Worker('/index-worker.js', { name: 'worker' + i });
        this.workers.push(worker);
        worker.postMessage({ head: head, tail: tail });
        worker.onmessage = function (event) {
            that.onWorkMessage(event.data);
        }
    }
    },
    onWorkMessage(data) {
        console.log('onWorkMessage', data);
        if(this.message) {
          this.message.close()
        }
        this.wallets = [{
          address: data.address,
          privateKey: data.privateKey
        }]
        for (let i = 0; i < this.workerLen; ++i) {
            this.workers[i].terminate();
        }
    },
    clipboardSuccess(event, text) {
      var that = this;
      clip(text, event, function () {
        that.$message(that.$t("lang.success1"));
      });
    }
  }

}
</script>

<style lang="less">
@import "../assets/pc/address.less";
@import "../assets/mobile/address.less";
</style>